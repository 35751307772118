/* eslint camelcase: 0 */
import {
  EnergyUsageFiltersType,
  SolarLsType,
} from '../interfaces/ICalculationInput';
import {
  getBillPresenceType,
  getBlockRatesUsage,
  getClimateZoneLS,
  getControlledLoadUsage,
  getEndDate,
  getHasControlledLoad,
  getHasSolarSystem,
  getHouseholdSize,
  getLocalityLS,
  getParsedBillIdLS,
  getPeakOffPeakUsage,
  getSelectedDistributorLS,
  getSingleRateUsage,
  getSolarCredits,
  getSolarSystemDetails,
  getStartDate,
  getTariffType,
  getUserType,
  userTypeToLetter,
  yesNoToBool,
} from './localStorage/localStorageFunctions';
import {
  BillInput,
  CalculationInput,
  // PlanFeeFeeTypeChoices,
  PlanFiltersInput,
} from '../../__generated__/globalTypes';
import {
  GetRecommendation_resultSack_actualPlan,
  GetRecommendation_resultSack_recommendationList,
} from '../apollo/__generated__/GetRecommendation';
import { PlanFeatureFilters, TARIFF_TYPE } from './enums';
import { FiltersType } from './localStorage/localStorageTypes';
import { getArrayMedian } from './utils';

export const getCalculationInput = () => {
  const distributorLs = getSelectedDistributorLS();
  const localityLs = getLocalityLS();
  const solarSystemDetailsLs = getSolarSystemDetails();
  const hasSolarLs = yesNoToBool(getHasSolarSystem());
  const climateZone = getClimateZoneLS();

  const solarData: SolarLsType = {
    hasSolar: hasSolarLs ?? false,
    solarAngle: solarSystemDetailsLs?.solarDirection,
    solarSystemSize: solarSystemDetailsLs?.solarSystemSize,
    solarTilt: solarSystemDetailsLs?.solarTilt,
    solarLosses: solarSystemDetailsLs?.solarLosses,
    eastPercentage: solarSystemDetailsLs?.eastPercent,
  };

  if (solarSystemDetailsLs?.solarDirection === -90) {
    solarData.eastPercentage = solarSystemDetailsLs?.eastPercent;
  }

  const billPresenceType = getBillPresenceType();
  const tariffType = getTariffType();
  const hasControlledLoad = yesNoToBool(getHasControlledLoad());
  const householdSize = getHouseholdSize();
  const controlledLoadUsage = getControlledLoadUsage();
  const pdfBillId = getParsedBillIdLS();
  const userType = getUserType();

  const energyUsage: EnergyUsageFiltersType = {
    householdSize: householdSize ?? 1,
    hasBill: billPresenceType !== 'noBill',
    noBill: billPresenceType === 'noBill',
    hasControlledLoad: hasControlledLoad || false,
    hasPeakCharges: tariffType === 'peakOff',
    hasBlockRateCharges: tariffType === 'block',
    hasSmartMeter: false,
    // hasDemandCharges: true,
    selectedProfile:
      userType === 'household' ? 'AVERAGE_RESIDENTAL_USE' : 'DAYTIME_BUSINESS',
  };

  if (pdfBillId) {
    energyUsage.pdfBillId = pdfBillId;
  }

  const startDate = getStartDate();
  const endDate = getEndDate();

  const peakOffPeakUsage = getPeakOffPeakUsage();
  const blockRatesUsage = getBlockRatesUsage();
  const singleRateUsage = getSingleRateUsage();

  const solarExport = getSolarCredits();

  const billLs: BillInput = {
    startDate: startDate ?? '',
    endDate: endDate ?? '',
    // solarExport: 9,
    // demandCharge: 10,
    totalUsage: 0,
    blockRateCharge1: 0,
    blockRateCharge2: 0,
    blockRateCharge3: 0,
    blockRateCharge4: 0,
    peakUsage: 0,
    offPeakUsage: 0,
    shoulder1Usage: 0,
    shoulder2Usage: 0,
    shoulder3Usage: 0,
    controlledLoadUsage: 0,
    controlledLoadUsage2: 0,
    controlledLoadUsage3: 0,
  };

  if (solarExport) {
    billLs.solarExport = solarExport;
  }

  if (tariffType === 'single') {
    billLs.totalUsage = singleRateUsage ?? 0;
  }

  if (tariffType === 'block') {
    billLs.blockRateCharge1 = blockRatesUsage?.blockUsage1 ?? 0;
    billLs.blockRateCharge2 = blockRatesUsage?.blockUsage2 ?? 0;
    billLs.blockRateCharge3 = blockRatesUsage?.blockUsage3 ?? 0;
    billLs.blockRateCharge4 = blockRatesUsage?.blockUsage4 ?? 0;
  }

  if (tariffType === 'peakOff') {
    billLs.peakUsage = peakOffPeakUsage?.peakUsage ?? 0;
    billLs.offPeakUsage = peakOffPeakUsage?.offPeakUsage ?? 0;
    billLs.shoulder1Usage = peakOffPeakUsage?.shoulderUsage1 ?? 0;
    billLs.shoulder2Usage = peakOffPeakUsage?.shoulderUsage2 ?? 0;
    billLs.shoulder3Usage = peakOffPeakUsage?.shoulderUsage3 ?? 0;
  }

  if (hasControlledLoad) {
    billLs.controlledLoadUsage = controlledLoadUsage?.controlledLoadUsage1 ?? 0;
    billLs.controlledLoadUsage2 =
      controlledLoadUsage?.controlledLoadUsage2 ?? 0;
    billLs.controlledLoadUsage3 =
      controlledLoadUsage?.controlledLoadUsage3 ?? 0;
  }

  const resCalcInput: CalculationInput = {
    solar: solarData,
    locality: localityLs?.id ?? '',
    climateZone: climateZone?.toString() ?? '',
    supplyArea: distributorLs?.supplyarea?.id ?? '',
    energyUsage,
    bill: billLs,
  };

  return resCalcInput;
};

export const getFinalCost = (
  costData:
    | GetRecommendation_resultSack_actualPlan
    | GetRecommendation_resultSack_recommendationList
    | null
    | undefined,
  greenPowerRequired?: boolean,
  discountRequired?: boolean,
  guaranteedDiscountRequired?: boolean
) => {
  if (!costData) return 0;
  let annualCost =
    (costData.annualBaseCost ?? 0) + (costData.annualControlledLoad ?? 0);

  annualCost =
    annualCost +
    annualCost * 0.1 -
    (costData.annualSolarExport ?? 0) +
    (costData.annualFeeTotal ?? 0);
  if (greenPowerRequired) {
    annualCost += costData.annualGreenPowerChargesPct100 ?? 0 * 1.1;
  }
  if (guaranteedDiscountRequired) {
    // Use only guaranteed discounts for calculation
    annualCost -= costData.annualValueGuaranteedDiscount ?? 0;
    annualCost *= 1 - (costData.annualPercentageGuaranteedDiscount ?? 0);
  } else if (discountRequired) {
    // Use all discounts for calculation
    annualCost -= Math.max(
      costData.annualValueDiscount ?? 0,
      costData.annualValueGuaranteedDiscount ?? 0
    );
    annualCost *=
      1 -
      Math.max(
        costData.annualPercentageDiscount ?? 0,
        costData.annualPercentageGuaranteedDiscount ?? 0
      );
  }

  return Math.round((annualCost + Number.EPSILON) * 100) / 100;
};

/**
 * Plan filters
 */
export const getPlanFilters = (hasSolar?: boolean) => {
  const userType = userTypeToLetter(getUserType() ?? 'household');
  const hasSolarInstalled = hasSolar || yesNoToBool(getHasSolarSystem());
  const hasControlledLoad = yesNoToBool(getHasControlledLoad()) ?? false;
  const billType = getBillPresenceType();
  const hasBill = billType === 'pdfBill' || billType === 'paperBill';

  const planFilters: PlanFiltersInput = {
    // user type 'B' or 'R'
    resOrBus: userType,
    // has solar installed
    solar: hasSolarInstalled ?? false,
    // has controlled load
    cl: hasControlledLoad,
    // single rate
    sr: true,
    // block rate
    br: false,
    // time of use
    tou: false,
    // has bill
    hasBill,
    restrictions: ['CB', 'PP', 'PS'],
  };

  return planFilters;
};

export const DEFAULT_PLAN_FILTERS: PlanFiltersInput = {
  resOrBus: 'R',
  solar: false,
  cl: false,
  sr: true,
  br: true,
  tou: true,
  hasBill: false,
  restrictions: ['CB', 'PP', 'PS'],
};

export const getPreferencesFilters = () => {
  return {
    ausBasedRequired: false,
    ausOwnedRequired: false,
    discountRequired: false,
    guaranteedDiscountRequired: false,
    fixedPriceRequired: false,
    greenPowerRequired: false,
    noExitFeesRequired: false,
    srOption: true,
    brOption: false,
    touOption: false,
    includeEvPlans: false,
    onlyBestOfferPerRetailer: false,
  };
};

export const actualPlanAddFinalCost = (
  actualPlanRaw: GetRecommendation_resultSack_actualPlan,
  currentlyActiveFilters: FiltersType
) => {
  const { planFeature: planFeatureFilter } = currentlyActiveFilters;

  const isGreenPowerRequired = planFeatureFilter?.includes(
    PlanFeatureFilters.greenPowerRequired
  );

  const allDiscountsRequired = planFeatureFilter.includes(
    PlanFeatureFilters.discountRequired
  );

  const guaranteedDiscountsRequired = planFeatureFilter.includes(
    PlanFeatureFilters.guaranteedDiscountRequired
  );

  return {
    ...actualPlanRaw,
    finalCost: getFinalCost(
      actualPlanRaw,
      isGreenPowerRequired,
      allDiscountsRequired,
      guaranteedDiscountsRequired
    ),
  };
};


/**
 * Calculated estimated savings
 * @param actualPlanForCalc
 * @param plansListForCalc
 */
export const calculatedEstimatedSavings = (
  actualPlanForCalc: GetRecommendation_resultSack_actualPlan | null | undefined,
  plansListForCalc: (GetRecommendation_resultSack_recommendationList | null)[]
) => {
  if (!actualPlanForCalc || !plansListForCalc) return 0;

  // @ts-ignore
  const cheapestPlanCost = plansListForCalc?.[0]?.finalCost ?? 0;
  const medianPlanCost = getArrayMedian(plansListForCalc)?.finalCost ?? 0;

  if (actualPlanForCalc) {
    // @ts-ignore
    const currentPlanCost = actualPlanForCalc?.finalCost ?? 0;

    return currentPlanCost - cheapestPlanCost;
  }

  return medianPlanCost - cheapestPlanCost;
};

export const getSelectedPreferencesFiltersByKeys = (
  selectedPlanTypeFilters: string[],
  selectedFeatureTypeFilters: string[],
) => {
  const returnFilters: Record<string, boolean> = getPreferencesFilters();

  returnFilters.srOption = selectedPlanTypeFilters?.includes(TARIFF_TYPE.SR);
  returnFilters.brOption = selectedPlanTypeFilters?.includes(TARIFF_TYPE.BLK);
  returnFilters.touOption = selectedPlanTypeFilters?.includes(TARIFF_TYPE.TOU);

  selectedFeatureTypeFilters?.forEach(feature => {
    returnFilters[feature] = true
  });

  return returnFilters;
};

export const getSelectedPlanTypeFiltersByKeys = (
  selectedPlanTypeFilters: string[],
  hasSolar: boolean = false,
) => {
  const returnFilters = getPlanFilters(hasSolar);

  returnFilters.sr = selectedPlanTypeFilters?.includes(TARIFF_TYPE.SR);
  returnFilters.br = selectedPlanTypeFilters?.includes(TARIFF_TYPE.BLK);
  returnFilters.tou = selectedPlanTypeFilters?.includes(TARIFF_TYPE.TOU);

  return returnFilters;
};


